import React from 'react';
import MainHeader from '../components/MainHeader';
import secondPhoto from "../assets/about_us_second_image.png";

const AboutUs = () => {
  return (
    <>
        <div className='bg-about-us bg-image'></div>
        <MainHeader/>
        <div className='about-us-hero position-relative'>
          <h2 className='about-us-hero-title position-absolute bg-dark fs-max w-100 text-light px-3 py-2 py-md-3'>Артур Буздов</h2>
        </div>
        <div className="container-90 text-primary">
          <h2 className='fs-max'>Резюме</h2>
          <p className='fs-regular rockstar fw-bold my-3 my-md-5'>Специалист в области общей и специальной физической подготовки, гимнастики, растяжки и IQ подготовки, координации движений, развитии мозговой деятельности профессиональных бойцов ММА, и спортсменов игровых видов спорта <br/>
          <br/>
          Автор методики Art_Structura – искусство структурирования движений по назначению.<br/>
          <br/>
          Защитил кандидатскую диссертацию по педагогическим наукам 28 апреля 2009 года, имеет учёное звание – кандидата педагогических наук с 18.12.2009 г, и учёную степень доцента с 16.07.2015 г., научно-педагогический стаж составляет 18 лет.<br/>
          <br/>
          Сочетаю традиционные элементы общепризнанной системы специальной физической подготовки и свои индивидуальные наработки.<br/>
          <br/>
          Восстанавливаю связочный аппарат бойцов и спортсменов игровых видов спорта после травм.
          Даю методики на развитие периферического зрения с использованием таблиц Шульте, Горбова, корректурных проб Бурдона.<br/><br/>
          Применяю в своей методике тесты на внимательность, практические упражнения для активизации работы центральной нервной системы, упражнения способствующие интеграции нейронной сети в обоих полушариях головного мозга, и использую методы одновременного выполнения нескольких задач с изменением обстановки.<br/>
          <br/>
          Данные методики являются приобретённым опытом многолетней тренерской и исследовательской деятельности. 
          Даю специальную подготовку, чтобы спортсмен был лучше в своём спорте, и общую подготовку, чтобы спортсмен был здоровым, сильным, чтобы улучшать свой уровень.<br/>
          <br/>
          Находясь в потоке, я стимулирую определённые мышцы, которые не задействованы в основном виде деятельности.  Использование знаний в гимнастике, позволяет мне работать с весом собственного тела, потому что такие упражнения идеальны для центральной нервной системы.<br/>
          <br/>
          Я считаю, что обучение нужно адаптировать под конкретную личность, не используя общепринятую систему подготовки спортсменов высшей категории.<br/>
          <br/>
          Имею 50 научных публикаций, в том числе, участие в четырех международных конференциях. Являюсь соавтором восьми учебно-методических пособий, и двенадцати электронных учебников и рацпредложений. 
          Опыт тренерской работы 30 лет.<br/>
          <br/>
          Систематически повышаю свою квалификацию. В 2016 году прошел повышение квалификации в «Национальном государственном Университете физической культуры, спорта и здоровья имени П.Ф. Лесгафта, Санкт-Петербург».<br/>
          <br/>
          Регистрационный номер 161490, дата выдачи 28.11.2016, по программе Современные методики подготовки спортсменов в комплексных (смешанных) единоборствах: панкратион, рукопашный бой, универсальный бой, ММА, спортивно-боевое самбо.<br/><br/>
          Являюсь тренером по спортивной гимнастике, рукопашному бою и регби -13. Подготовил восемь мастеров спорта по рукопашному бою, 18 КМС, 54 спортсмена первого разряда, трёх мастеров спорта по спортивной гимнастике, и 35 КМС по регби-13.
          </p>
          <img className='w-100' src={secondPhoto}/>
          <h2 className='fs-max'>Портфолио</h2>
          <p className='fs-regular rockstar fw-bold my-3 my-md-5'>
            Имею опыт работы в клубах: Александр Невский, RusFighters, АСВ, FFS, Вистерия, Белые волки, Кристалл.<br/>
            <br/>
            Провожу персональные тренировки со спортсменами мирового уровня, такими как:<br/>
            <br/>
            1.	Яна Куницкая – боец UFC.<br/>
            <br/>
            2.	Шамиль Гамзатов – боец UFC и PFL.<br/>
            <br/>
            3.	Павел Витрук – экс-чемпион M1 global. Боец клуба Рать.<br/>
            <br/>
            4.	Максим Дивнич – экс-чемпион M1 global, боец ММА, лига RCC. <br/>
            <br/>
            5.	Марат Балаев – экс-чемпион ACB, боец АСА.<br/>
            <br/>
            6.	Мухамадрасул Гаджиев – чемпион России по джиу-джитсу.<br/>
            <br/>
            7.	Игорь Исайкин – пятикратный чемпион мира по боевому самбо.<br/>
            <br/>
            8.	Никита Чистяков – экс-чемпион M1 global. Боец клуба Рать.<br/>
            <br/>
            9.	Адлан Батаев – чемпион мира, боец АСА.<br/>
            <br/>
            10.	Мухамад Гиназов – боец ММА, топ АСА.<br/>
            <br/>
            11.	Бек Исаков – профессиональный боец ММА и тренер.<br/>
            <br/>
            12.	Работал со сборной Киргизии по ММА.<br/>
            <br/>
            13.	Заур Азизов – боец ММА, лига RCC.<br/>
            <br/>
            14.	Кристина Дудаева – член сборной России по вольной борьбе.<br/>
            <br/>
            15.	Артур Аджиев – профессиональный боец ММА.<br/>
            <br/>
            16.	Муслим Салихов – боец UFC, король кунг-фу, чемпион мира.<br/>
            <br/>
            17.	Заурбек Хасиев – борец стиля джиу-джитсу, президент   Российской лиги бразильского JJ.<br/>
            <br/>
            18.	Данила Приказа – Профессиональный боец ММА.<br/>
            <br/>
            19.	Рустам Талдиев – Трёхкратный чемпион мира по боевому самбо, профессиональный боец лиги АСА.<br/>
            <br/>
            20.	Тиаго Сантос - Боец UFC по прозвищу «МОЛОТ» номер 1 рейтинга UFC.<br/>
            <br/>
            21.	Руслан Бикменов – Мастер спорта по тайскому боксу, мастер спорта по боксу, мастер спорта международного класса по кикбоксингу, двукратный чемпион мира по кикбоксингу, двукратный обладатель кубка мира по кикбоксингу.<br/>
            <br/> 
            22.	    Алексей Буторин – Боец ММА, на данный момент провел боев: 19, из которых победил 15 и проиграл 4. Принимал участие в турнирах таких промоушенов как ACA, MG, ONE FC. Является №1 лиги АСА.
          </p>
        </div>
    </>
  )
}

export default AboutUs